<template>
	<div class="login">
		<div class="form">

			<el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">

				<!-- <h3 class="title"> -->

				<!-- <img src="../../assets/logo/logo2.png" alt=""> -->
				<!-- </h3> -->
				<div class="title">可视化数智平台登录</div>
				<div class="logininput">
					<el-form-item prop="enterpriseName">
						<el-input v-model="loginForm.enterpriseName" type="text" auto-complete="off" placeholder="院校">
							<svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
						</el-input>
					</el-form-item>
					<el-form-item prop="userName">
						<el-input v-model="loginForm.userName" type="text" auto-complete="off" placeholder="账号">
							<svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
						</el-input>
					</el-form-item>
					<el-form-item prop="password">
						<el-input v-model="loginForm.password" type="password" auto-complete="off" placeholder="密码"
							@keyup.enter.native="handleLogin">
							<svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
						</el-input>
					</el-form-item>
					<el-form-item prop="code" v-if="captchaOnOff">
						<el-input v-model="loginForm.code" auto-complete="off" placeholder="验证码" style="width: 63%"
							@keyup.enter.native="handleLogin">
							<svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon" />
						</el-input>
						<div class="login-code">
							<img :src="codeUrl" @click="getCode" class="login-code-img" />
						</div>
					</el-form-item>

					<el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px; color: #20dcf3;">记住密码
					</el-checkbox>
					<el-form-item style="width:100%;">
						<el-button :loading="loading" size="medium" type="primary" style="width:100%;"
							@click.native.prevent="handleLogin">
							<span v-if="!loading">登 录</span>
							<span v-else>登 录 中...</span>
						</el-button>
						<div style="float: right;" v-if="register">
							<router-link class="link-type" :to="'/register'">立即注册</router-link>
						</div>
					</el-form-item>
				</div>


			</el-form>
		</div>

		<!--  底部  -->
		<div class="el-login-footer">
			<span style="color: #5b5b5b; cursor:pointer;" @click="jump">Copyright © 山东全通网融 鲁ICP备2021032746号</span>
		</div>
	</div>
</template>

<script>
	import {
		getCodeImg
	} from "@/api/login";
	import Cookies from "js-cookie";
	import {
		encrypt,
		decrypt
	} from '@/utils/jsencrypt'

	export default {
		name: 'Login',
		data() {
			return {
				codeUrl: '',
				loginForm: {
					enterpriseName: '',
					userName: '',
					password: '',
					rememberMe: false,
					code: '',
					uuid: ''
				},
				loginRules: {
					enterpriseName: [{
						required: true,
						trigger: 'blur',
						message: '请输入您的企业账号'
					}],
					userName: [{
						required: true,
						trigger: 'blur',
						message: '请输入您的员工账号'
					}],
					password: [{
						required: true,
						trigger: 'blur',
						message: '请输入您的密码'
					}],
					code: [{
						required: true,
						trigger: 'change',
						message: '请输入验证码'
					}]
				},
				loading: false,
				// 验证码开关
				captchaOnOff: true,
				// 注册开关
				register: false,
				redirect: undefined
			}
		},
		watch: {
			$route: {
				handler: function(route) {
					this.redirect = route.query && route.query.redirect
				},
				immediate: true
			}
		},
		created() {
			this.getCode()
			this.getCookie()
		},
		methods: {
			jump() {
				window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank')
			},
			getCode() {
				getCodeImg().then(res => {
					this.captchaOnOff = res.data.captchaOnOff === undefined ? true : res.data.captchaOnOff
					if (this.captchaOnOff) {
						this.codeUrl = 'data:image/gif;base64,' + res.data.img
						this.loginForm.uuid = res.data.uuid
					}
				})
			},
			getCookie() {
				let enterpriseName = Cookies.get('enterpriseName')
				const userName = Cookies.get('userName')
				const password = Cookies.get('password')
				const rememberMe = Cookies.get('rememberMe')
				this.loginForm = {
					enterpriseName: enterpriseName = undefined ? this.loginForm.enterpriseName : enterpriseName,
					userName: userName === undefined ? this.loginForm.userName : userName,
					password: password === undefined ? this.loginForm.password : decrypt(password),
					rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
				}
			},
			handleLogin() {
				// console.log(this.loginForm)
				this.loginForm.enterpriseName = 'sykj'
				// return
				this.$refs.loginForm.validate(valid => {
					if (valid) {
						this.loading = true
						if (this.loginForm.rememberMe) {
							Cookies.set('enterpriseName', this.loginForm.enterpriseName, {
								expires: 30
							})
							Cookies.set('userName', this.loginForm.userName, {
								expires: 30
							})
							Cookies.set('password', encrypt(this.loginForm.password), {
								expires: 30
							})
							Cookies.set('rememberMe', this.loginForm.rememberMe, {
								expires: 30
							})
						} else {
							Cookies.remove('enterpriseName')
							Cookies.remove('userName')
							Cookies.remove('password')
							Cookies.remove('rememberMe')
						}
						this.$store.dispatch('Login', this.loginForm).then(() => {
							this.$router.push({
								path: this.redirect || '/'
							}).catch(() => {})
						}).catch(() => {
							this.loading = false
							if (this.captchaOnOff) {
								this.getCode()
							}
						})
					}
				})
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.login {
		// display: flex;
		// justify-content: flex-end;
		// padding-right: 80px;
		// align-items: center;
		height: 100%;
		// background-image: url("../assets/bj.gif");
		background-size: cover;



		height: 100%;
		margin: 0 auto;
		z-index: 10;
		box-shadow: 0 0 30px rgba(0, 0, 0, .1);
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 5%;
	}


	.title {
		width: 100%;
		display: flex;
		line-height: 78px;
		justify-content: center;
		font-size: 42px;
		font-weight: bold;
		letter-spacing: 2px;
		background: linear-gradient(90deg, #04a8f0, #46daff, #4177ed, #04a8f0);
		background-size: cover;
		-webkit-background-clip: text;
		background-clip: text;
		-webkit-text-fill-color: transparent;
		text-fill-color: transparent;
		font-family: "Microsoft YaHei", "HanHei SC", "Helvetica Neue", "Open Sans", Arial, "Hiragino Sans GB", "微软雅黑", STHeiti, "WenQuanYi Micro Hei", SimSun, sans-serif;
		margin: 0px auto 42px auto;
		// text-align: center;

		// >img {
		// 	width: 150px;
		// }
	}


	.form {
		margin-top: 15px;
		position: relative;
		border: none;
		box-shadow: inset 0 0 40px rgb(0 150 255 / 70%);
	}

	.login-form {
		border-radius: 6px;
		// background: #393d79;
		width: 500px;
		padding: 25px 25px 5px 25px;
		// opacity: 0.6;
		margin-top: 50px;

		::v-deep .el-input__inner {
			height: 38px;
			border: 1px solid #6a87cd;
			background: rgba(32, 163, 245, .2);
			color: #fff;
			input {
				height: 38px;
			}
		}

		.input-icon {
			height: 39px;
			width: 14px;
			margin-left: 2px;
		}
	}

	.logininput {
		width: 70%;
		margin: 0 auto;
	}

	.login-tip {
		font-size: 13px;
		text-align: center;
		color: #bfbfbf;
	}

	.login-code {
		width: 33%;
		height: 38px;
		float: right;

		img {
			cursor: pointer;
			vertical-align: middle;
		}
	}

	.el-login-footer {
		height: 40px;
		line-height: 40px;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		text-align: center;
		color: #fff;
		font-family: Arial;
		font-size: 12px;
		letter-spacing: 1px;
	}

	.login-code-img {
		height: 38px;
	}
</style>
